import React from 'react';
import {useMediaQuery} from 'react-responsive';
import Banner from './images/TFC-Store-Closing-banner-Desktop-v2.jpg';
import TabletBanner from './images/TFC-Store-Closing-banner-Mobile-v1.jpg';
import './App.css';

function App() {
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 769px)'});
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div className="App">
      <header className="App-header" role="presentation" aria-label="TFC Championship Ring Collection">
      { isDesktopOrLaptop && <>
        <img src={Banner} alt="TFC Championship Ring Collection" />
        </>}
        { isTabletOrMobile  && <>
          <img src={TabletBanner} alt="TFC Championship Ring Collection" />
          </>}
      </header>
    </div>
  );
}

export default App;
